<template>
  <main>
    <div>
      <article class="main core">
        <div class="mb20 row xl normal">
          <span class="mr5">全部商品</span>
          <span class="primary">{{ cartData.total_num }}</span>
        </div>
        <div class="operate-t row mb20">
          <el-checkbox
            v-model="allSelect"
            label="全部"
            name="type"
            class="flex1"
            :disabled="disabled"
          ></el-checkbox>
          <span class="pro-name flex2">商品</span>
          <span class="flex1">单价</span>
          <span class="flex1">数量</span>
          <span class="flex1">小计</span>
          <span class="flex1 row-center">操作</span>
        </div>
        <div
          class="pro-list mb20"
          v-for="(item, index) in cartList"
          :key="index"
        >
          <div class="shop-info">
            <!-- :label="item.shop.shop_name" -->
            <el-checkbox
              v-model="item.is_selected"
              :false-label="0"
              :true-label="1"
              @change="setSelectStatus(item, 'shop')"
            ></el-checkbox>
          </div>
          <div
            class="pro-item row pr"
            :class="{ expire: false }"
            v-for="(itemTwo, indexTwo) in item.cart"
            :key="indexTwo"
          >
            <div class="item-pro flex3 row">
              <div class="status">
                <el-checkbox
                  v-model="itemTwo.selected"
                  :false-label="0"
                  :true-label="1"
                  v-if="itemTwo.goods_status"
                  @change="setSelectStatus(itemTwo)"
                ></el-checkbox>
                <span v-else class="nr lighter pr no">失效</span>
              </div>
              <el-image
                class="pro-img"
                :src="itemTwo.image"
                fit="cover"
              ></el-image>
              <div class="item-title column">
                <router-link
                  class="title line2 normal mb5"
                  :to="`/prodetail?id=${itemTwo.goods_id}`"
                >
                  {{ itemTwo.goods_name }}
                </router-link>
                <div class="intro sm warn line1">
                  {{ itemTwo.spec_value_str }}
                </div>
              </div>
            </div>
            <div class="item-price column flex1">
              <!-- <make-priceformat
                price="89"
                class="mr10"
                :subscriptSize="16"
                :firstSize="16"
                :secondSize="16"
                color="#888888"
              ></make-priceformat> -->
              <make-priceformat
                :price="itemTwo.price"
                class="mr10"
                :subscriptSize="13"
                :firstSize="20"
                :secondSize="20"
                color="#000000"
              ></make-priceformat>
            </div>
            <div class="item-num flex1">
              <el-input-number
                v-model="itemTwo.goods_num"
                :min="1"
                :max="itemTwo.stock"
                @change="setCartChangeNum(itemTwo.cart_id, itemTwo.goods_num)"
              ></el-input-number>
            </div>
            <div class="item-count flex1">
              <make-priceformat
                :price="
                  parseFloat(
                    itemTwo.goods_num * parseFloat(itemTwo.price)
                  ).toFixed(2)
                "
                class="mr10"
                :subscriptSize="13"
                :firstSize="20"
                :secondSize="20"
              ></make-priceformat>
            </div>
            <div class="item-operate column-center flex1">
              <span class="cursor" @click="delCart(itemTwo.cart_id)">删除</span>
              <span
                class="cursor"
                @click="
                  handleSettle(
                    (data = { shop_id: item.shop.shop_id, item: itemTwo })
                  )
                "
                >结算</span
              >
              <span class="cursor" @click="changeStatus(itemTwo.goods_id)">{{
                itemTwo.is_collect ? "取消收藏" : "收藏宝贝"
              }}</span>
            </div>
          </div>
        </div>

        <el-empty
          class="bg-white mb30"
          description="赶快把喜欢的商品存入购物车噢~"
          v-if="disabled"
        ></el-empty>

        <div class="operate-b row-between nr lighter">
          <div class="right row">
            <el-checkbox
              v-model="allSelect"
              label="全部"
              name="type"
              class="flex1"
              :disabled="disabled"
            ></el-checkbox>
            <span class="ml30 cursor nr" @click="delCart(nowSelectList)"
              >删除</span
            >
          </div>
          <div class="left row">
            <p class="nr lighter row">
              已选商品<span class="price xl">{{ nowSelectList.length }}</span
              >件<em class="mr30"></em>共计(不含运费):
              <make-priceformat
                :price="cartData.total_amount"
                class="mr10"
                :subscriptSize="22"
                :firstSize="22"
                :secondSize="22"
                color="#C91623"
              ></make-priceformat>
            </p>
            <button type="cart" class="white lg" @click="handleSettle()">
              结算
              <!-- toSettle(nowSettlementList, nowSelectList, nowSelectPrice), -->
            </button>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      num: 1,
      cartData: [], //购物车数据
      cartList: [], //购物车列表
      disabled: true,
      nowSelectPrice: 0, //总价格
      nowSettlementList: [], //结算订单
    };
  },
  computed: {
    /**
     * @description 全选按钮状态
     * @return {Bolearn} 选中状态
     */
    allSelect: {
      get() {
        this.disabled = this.cartList.length ? false : true;
        return (
          this.cartList && this.cartList.every((el) => el.is_selected === 1)
        );
      },
      set(val) {
        this.setSelectStatus({ selected: val ? 1 : 0 }, "all");
      },
    },
    /**
     * @description 当前选中商品列表
     * @return {Array} 选中列表
     */
    nowSelectList() {
      let nowSelectList = [];
      this.nowSettlementList = this.cartList.map((el) => ({
        cart: el.cart.filter((item) => {
          if (item.selected == 1) {
            nowSelectList.push(item.cart_id);
            this.nowSelectPrice += parseFloat(
              item.goods_num * parseFloat(item.price)
            );
            return true;
          } else {
            return false;
          }
        }),
        ...el.shop,
      }));
      return nowSelectList;
    },
  },
  watch: {},
  methods: {
    /**
     * @description 删除购物车
     * @param {Array} cart_id
     * @return void
     */
    delCart(cart_id) {
      this.$confirm("删掉的商品将可能被别人抢走哦~要删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "再想想",
        type: "warning",
        center: true,
        cancelButtonClass: "cancelBtn",
        confirmButtonClass: "confirmBtn",
      })
        .then(() => {
          this.$api
            .delCartApi({
              cart_id,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getCartList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeStatus(id) {
      let isLogin = !!this.$store.getters.userInfo.token; // 登录状态
      if(isLogin){
        this.$api
          .changeStatusApi({
            goods_array: [id],
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({ message: res.msg, type: "success" });
              this.getCartList();
            }
          });
      }else{
        this.$router.replace("/login");//跳转到登录页面
      }
    },
    /**
     * @description 获取购物车列表
     * @return void
     */
    getCartList() {
      this.$api.getCartListApi().then((res) => {
        this.cartData = res.data;
        this.cartList = res.data.lists;
      });
    },
    /**
     * @description 单个商品数据处理
     * @param {Object} shop_id 商品ID
     * @param {Object} item 当前结算物品
     * @return void
     */
    handleSettle(obj = null) {
      let data = [];
      let cart_id = [];
      // 单品结算
      if (obj) {
        let { shop_id, item } = obj;
        data = [
          {
            item_id: item.item_id,
            num: item.goods_num,
            goods_id: item.goods_id,
            shop_id,
          },
        ];
        cart_id = [item.cart_id];
      } else {
        this.cartList.forEach((item) => {
          item.cart.forEach((el) => {
            if (el.selected) {
              data.push({
                item_id: el.item_id,
                num: el.goods_num,
                goods_id: el.goods_id,
                shop_id: item.shop.shop_id,
              });
              cart_id.push(el.cart_id);
            }
          });
        });
      }
      let params = {
        data,
        cart_id: cart_id.join(","),
      };
      // return;
      this.toSettle(params);
    },
    /**
     * @description 前往结算页
     */
    toSettle(params) {
      this.$router.push({
        name: "Settle",
        query: {
          data: encodeURIComponent(JSON.stringify(params)),
        },
      });
    },
    /**
     * @description 跳去结算页
     * @param {Object} info
     * @return void
     */
    // toSettle(checkInfo, nowSelectPrice, nowSettlementList) {
    //   this.$Cache.set("cartInfo", {
    //     proList: checkInfo,
    //     nowSelectPrice,
    //     nowSettlementList,
    //   });
    //   this.$router.push("/settle");
    // },
    /**
     * @description 选中状态处理
     * @param {Object} data
     * @param {String} type
     * @return void
     */
    async setSelectStatus(data, type = "single") {
      let param = {};
      switch (type) {
        case "single":
          param = {
            cart_id: [data.cart_id],
            selected: data.selected,
          };
          break;
        case "shop":
          param = {
            cart_id: data.cart.map((el) => el.cart_id),
            selected: data.is_selected,
          };
          break;
        case "all":
          param = {
            cart_id: this.cartList
              .map((el) => {
                return el.cart.map((item) => {
                  return item.cart_id;
                });
              })
              .flat(),
            selected: data.selected,
          };
          break;
        default:
          break;
      }
      await this.$api.setCartSelectedApi(param);
      this.getCartList();
    },
    /**
     * @desciption 更改购物车商品选中数量
     * @param {Number} cart_id
     * @param {Number} goods_num
     * @return void
     */
    async setCartChangeNum(cart_id, goods_num) {
      await this.$api.setCartChangeNumApi({
        cart_id,
        goods_num,
      });
      this.getCartList();
    },
  },
  created() {
    this.getCartList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
main {
  background-color: #fff;

  .main {
    padding-top: 50px;
    padding-bottom: 80px;

    /deep/ .el-checkbox-group {
      font-size: inherit;
    }

    /deep/ .el-checkbox {
      align-items: center;
      display: flex;

      .el-checkbox__label {
        font-size: 16px;
        margin-right: 20px;
      }

      .el-checkbox__input {
        height: 14px;
        vertical-align: inherit;
      }
    }

    .operate-t,
    .operate-b {
      height: 52px;
      background: #f5f5f5;
      padding: 0 20px;
    }

    .pro-list {
      background-color: rgba($color: $-color-center, $alpha: 0.3);

      .shop-info {
        padding: 20px;
        border: $-solid-border;
        background-color: $-color-white;
      }

      .pro-item {
        border: $-solid-border;
        padding: 20px;
        border-top: none;

        &:last-child {
          margin-bottom: 20px;
        }

        &.expire {
          background-color: rgba(0, 0, 0, 0.2);
          cursor: not-allowed;

          &::before {
            content: "";
            left: 0;
            top: 0;
            background-color: rgba($color: #fff, $alpha: 0.8);
            width: 100%;
            height: 100%;
            position: absolute;
          }
        }

        /deep/ .item-num {
          .el-input-number {
            width: 76px;
            line-height: 25px;
            display: flex;
            align-items: row;

            .el-input-number__decrease,
            .el-input-number__increase {
              top: 0;
              left: 0;
              width: 14px;
              border: $-solid-border;
              height: 25px;
              line-height: 25px;
              font-size: 12px;
              border-radius: 0;
              background: linear-gradient(0deg, #fafafa, #ededed);
            }

            .el-input-number__increase {
              left: auto;
              right: 0;
            }

            .el-input__inner {
              padding: 0;
              margin: 0 16px;
              width: 44px;
              height: 25px;
              line-height: 25px;
              font-size: 14px;
              border-radius: 0;
              background: linear-gradient(0deg, #fafafa, #ededed);
            }
          }
        }

        .item-pro {
          align-items: flex-start;

          .status {
            width: 35px;

            span.no {
              left: -10px;
            }
          }

          .pro-img {
            width: 83px;
            height: 83px;
            margin-right: 20px;
          }

          .item-title {
            width: 250px;
          }

          /deep/ .el-checkbox__label {
            display: none;
          }
        }

        .item-operate {
          span {
            cursor: pointer;

            &:hover {
              color: $-color-primary;
            }
          }
        }
      }
    }

    .operate-b {
      span {
        margin: 0 5px;
      }

      button {
        height: 52px;
        width: 150px;
        position: relative;
        margin-right: -20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.confirmBtn,
.cancelBtn {
  border: none;
  height: 48px;
  line-height: 48px;
  border-radius: 1px;
  font-size: 18px;
}

.cancelBtn {
  background: $-color-border;
  padding: 0 40px;
  color: $-color-lighter;

  &:focus,
  &:hover {
    background: $-color-border;
    color: $-color-lighter;
  }
}

.confirmBtn {
  padding: 0 46px;
  letter-spacing: 2px;
  background: $-color-price;
  color: $-color-white;

  &:focus,
  &:hover {
    background: $-color-price;
    color: $-color-white;
  }
}

.el-message-box {
  border-radius: 0;

  .el-message-box__headerbtn {
    font-size: 20px;
  }

  .el-message-box__status {
    padding: 0;

    &::before {
      content: "";
      width: 100px;
      height: 100px;
      display: block;
      background-image: url(~@/assets/icon/icon-warn.png);
      background-size: 100%;
    }
  }

  .el-message-box__content {
    padding: 0 0 35px;
    font-size: 18px;
    color: #777;
  }
}
</style>
